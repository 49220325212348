import React from 'react';
import Helmet from 'react-helmet';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import queryString from 'query-string';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { SEO } from 'components';
import { RegistryContainer } from 'components/registry';
import { Layout } from 'layouts';

const Wrapper = styled.div`
  h1 {
    color: ${props => props.theme.colors.black.base};
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
  }
`;

const Registry = ({ location }) => {
  // Using query params is a cheap way to share links until we have a way to persist user-created pages
  const breakpoints = useBreakpoint();
  const searchQuery = (location.search && queryString.parse(location.search));

  return (
    <Layout location={location} background={breakpoints.m ? '' : 'teal'}>
      <Helmet title={'Visualize any AWS Cloud stack'} />
      <SEO
        description='Visualize any new AWS Cloud stack'
        banner='https://media.graphcms.com/output=format:jpg/nbVDfaJbSuejiDFgRWEQ'
      />
      <Wrapper>
        <RegistryContainer searchQuery={searchQuery} />
      </Wrapper>
    </Layout>
  );
};

export default Registry;

Registry.propTypes = {
  location: PropTypes.object
};
